import React, { useState, useEffect } from 'react'
import SubscriptionCard from './SubscriptionCard'
import { useNavigate } from "react-router-dom";

export default function Subscription() {

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const getSubscriptions = async () => {
    let token = localStorage.getItem("accessToken");
    try {
      let response = await fetch(`https://api.activetlife.com/api/subscription/plan?beneficiary=HotelPropertyOwner`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.json();

      return { ...data, statusCode: response.status };
    } catch (error) {
      return error;
    }
  }


  useEffect(() => {
    getSubscriptions().then((res) => {
      setSubscriptionPlans(res?.subscriptions || []);
    });
  }, [])



  const goToPayment = () => {

    if (parseInt(selectedPlan.totalPrice) == 0) 
      return alert("Please Select a Paid Plan")

    let token = localStorage.getItem("accessToken");
    const redirectionUrl = `https://hotel-management-dashboard.activetlife.com/session/signin`;
    const baseUrl = 'https://www.activetlife.com/Payments';
    const params = `?token=${token}&subscriptionId=${selectedPlan._id}&redirectionUrl=${redirectionUrl}&beneficiary=HotelPropertyOwner`;


    window.location.href = baseUrl + params;

  };

  return (
    <div className="conatiner mb-5">
      <div className="row">

        <div className="col-10 mt-5">

          <div className="row" style={{ border: '1px solid #808080', justifyContent: "center", alignItems: "center", padding: '20px', display: 'flex' }}>


            {subscriptionPlans.map((plan, index) => {
              return <SubscriptionCard key={index} plan={plan} setSelectedPlan={() => setSelectedPlan(plan)} selectedPlan={selectedPlan}></SubscriptionCard>
            })}

          </div>

          {
            selectedPlan &&
            <div onClick={goToPayment} style={{
              padding: '8px',
              borderRadius: '8px',
              backgroundColor: '#007bff',
              color: 'white',
              cursor: 'pointer',
              width: '100px',
              marginLeft: 'auto',
              marginRight: "auto",
              textAlign: "center",
              marginTop: "20px"
            }}>
              Continue
            </div>


          }
        </div>
      </div>
    </div>
  )
}
